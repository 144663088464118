import React from "react";
import "../styles/EmailRow.css";
import Delete from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined";
import axios from "axios";

function EmailRow({ id, title, refresh, subject, description, time }) {
  const deleteItem = () => {
    console.log("object");
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/items/${id}`)
      .then((res) => {
        refresh();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="emailRow">
      <div className="emailRow__options">
        <IconButton onClick={deleteItem}>
          <Delete />
        </IconButton>
      </div>

      <h3 className="emailRow__title">{title}</h3>

      <div className="emailRow__message">
        <h4>
          {subject} <span className="emailRow__description">{description}</span>
        </h4>
      </div>
      <p className="emailRow__time">{time}</p>
    </div>
  );
}

export default EmailRow;
