import React, { useState } from "react";
import { Button } from "@material-ui/core";
import "../styles/Login.css";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"; // Import error icon
import axios from "axios";
import { useHistory } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  let history = useHistory();

  const submit = (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message

    // Check if fields are empty
    if (!email || !password) {
      setErrorMessage("Username and password should not be empty."); // Set error message
      return; // Exit the function if fields are empty
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`, {
        // Append the endpoint to the base URL
        email,
        password,
      })
      .then((res) => {
        localStorage.setItem("ID", res.data._id);
        localStorage.setItem("userName", res.data.email);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setErrorMessage("Incorrect username or password. Please try again."); // Set error message
        } else {
          setErrorMessage("An unexpected error occurred. Please try again.");
        }
        console.log(err);
      });
  };

  return (
    <div className="login">
      <h1 style={{ color: "white" }}>Welcome to HandyApp!</h1>
      {errorMessage && ( // Render toast if there's an error
        <div
          style={{
            position: "fixed", // Change to fixed positioning
            bottom: "20px", // Position at the bottom
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#d9534f",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            zIndex: 1000,
            display: "flex",
            alignItems: "center", // Align items in the center vertically
          }}
        >
          <ErrorOutlineIcon style={{ marginRight: "10px" }} />{" "}
          {/* Error icon */}
          <span>{errorMessage}</span>
        </div>
      )}
      <form className="login__container" onSubmit={submit}>
        <div style={{ marginRight: "145px", marginBottom: "-23px" }}>
          <PersonOutlineOutlinedIcon style={{ marginBottom: -5 }} />
          <label className="label-login">Username</label>
        </div>
        <br />
        <input
          className="input-login"
          placeholder="Your Username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <div style={{ marginRight: "150px", marginBottom: "-23px" }}>
          <LockOutlinedIcon style={{ marginBottom: -5 }} />
          <label className="label-login">Password</label>
        </div>
        <br />
        <input
          className="input-login"
          placeholder="Your Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="login-button" onClick={submit}>
          Sign In
        </button>
        <button
          type="button" // Change to button to prevent form submission
          className="register-button"
          onClick={() => history.push("/register")}
        >
          Sign Up
        </button>
      </form>
    </div>
  );
}

export default Login;
