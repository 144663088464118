import { Button, IconButton } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "../styles/Sidebar.css";
import SidebarOption from "./SidebarOption";

import CategoryIcon from "@material-ui/icons/Category";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import TextField from "@material-ui/core/TextField";

import { UserContext } from "./Dashboard";

function Sidebar() {
  const userId = localStorage.getItem("ID");
  const [categories, setCategories] = useState([]);
  const [clickedItem, setClickedItem] = useState(0);
  const [input, setInput] = useState("");
  const [refresh, setRefresh] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/${userId}`)
      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err));
  }, [refresh]);

  const addCategory = (e) => {
    e.preventDefault();
    if (input === "") {
      alert("Enter Something.");
      return;
    }
    console.log(input);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/categories`, {
        category: input,
        user: userId,
      })
      .then((res) => {
        setInput("");
        setRefresh(!refresh);
      })
      .catch((err) => alert("error" + err));
  };

  return (
    <div className="sidebar">
      <h1 style={{}}>Categories</h1>
      {categories.map((category, index) => (
        <div
          onClick={() => {
            setClickedItem(index);
            user.setCategoryId(category._id);
            user.setCategoryName(category.category);
          }}
        >
          <SidebarOption
            key={index}
            Icon={CategoryIcon}
            title={category.category}
            DeleteIcon={DeleteIcon}
            selected={index === clickedItem ? true : false}
            id={category._id}
            refresh={() => setRefresh(!refresh)}
          />
        </div>
      ))}
      <form onSubmit={addCategory}>
        <TextField
          id="standard-basic"
          label="Add Category"
          style={{ width: "22%", position: "absolute", bottom: "40px" }}
          onInput={(e) => setInput(e.target.value)}
          autoComplete="off"
          value={input}
          // helperText="Empty Field"
          // error={error && input === '' ? true : false}
        />
      </form>
    </div>
  );
}

export default Sidebar;
