import React, { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"; // Import error icon
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../styles/Login.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  let history = useHistory();

  const submit = (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message

    // Check if fields are empty
    if (!email || !password || !password1) {
      setErrorMessage("All fields are required."); // Set error message
      return; // Exit the function if fields are empty
    }

    if (password !== password1) {
      setErrorMessage("Passwords do not match."); // Set error message
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/register`, {
        email,
        password,
      })
      .then((res) => {
        if (res.status === 201) {
          if (
            window.confirm(
              "Successfully registered. Do you want to login directly?"
            )
          ) {
            localStorage.setItem("ID", res.data._id);
            localStorage.setItem("userName", res.data.email);
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        setErrorMessage("Error while registering. Please try again."); // Set error message for unexpected errors
        console.log("Error while register:", err);
      });
  };

  return (
    <div className="login">
      <h1 style={{ color: "white" }}>Welcome to HandyApp!</h1>
      {errorMessage && ( // Render toast if there's an error
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#d9534f",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
          }}
        >
          <ErrorOutlineIcon style={{ marginRight: "10px" }} />
          <span>{errorMessage}</span>
        </div>
      )}
      <form className="register__container" onSubmit={submit}>
        <div style={{ marginRight: "145px", marginBottom: "-23px" }}>
          <PersonOutlineOutlinedIcon style={{ marginBottom: -5 }} />
          <label className="label-login">Username</label>
        </div>
        <br />
        <input
          className="input-login"
          placeholder="Your Username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <div style={{ marginRight: "150px", marginBottom: "-23px" }}>
          <LockOutlinedIcon style={{ marginBottom: -5 }} />
          <label className="label-login">Password</label>
        </div>
        <br />
        <input
          className="input-login"
          placeholder="Your Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <div style={{ marginRight: "81px", marginBottom: "-23px" }}>
          <LockOutlinedIcon style={{ marginBottom: -5 }} />
          <label className="label-login">Confirm Password</label>
        </div>
        <br />
        <input
          className="input-login"
          placeholder="Confirm Your Password"
          type="password"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
        />
        <button type="submit" className="login-button">
          Sign Up
        </button>
        <button
          type="button" // Change to button to prevent form submission
          className="register-button"
          onClick={() => history.push("/login")}
        >
          Sign In
        </button>
      </form>
    </div>
  );
}

export default Register;
