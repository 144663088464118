import React, { createContext, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import EmailList from "./EmailList";
import "../styles/Dashboard.css";

export const UserContext = createContext();

function Dashboard() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const setCategoryId = (e) => {
    setSelectedCategory(e);
  };

  const setCategoryName = (e) => {
    setSelectedCategoryName(e);
  };

  return (
    <UserContext.Provider
      value={{
        selectedCategory,
        setCategoryId,
        setCategoryName,
        selectedCategoryName,
      }}
    >
      <div className="App">
        <Header />
        <div className="app__body">
          <Sidebar />
          <EmailList />
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default Dashboard;
