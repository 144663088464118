import React, { useContext } from "react";
import "../styles/SidebarOption.css";
import axios from "axios";
import { IconButton } from "@material-ui/core";
import { UserContext } from "./Dashboard";

function SidebarOption({ Icon, title, DeleteIcon, selected, id, refresh }) {
  const user = useContext(UserContext);

  const deleteCategory = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/${id}`)
      .then((res) => {
        console.log(res);
        user.setCategoryId("");
        user.setCategoryName("");
        refresh();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={`sidebarOption ${selected && "sidebarOption--active"}`}>
      <Icon />
      <h3>{title}</h3>
      <IconButton
        onClick={deleteCategory}
        className={`sidebarOption ${selected && "sidebarOption--active"}`}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default SidebarOption;
