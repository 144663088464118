import React, { useContext, useState, useEffect } from "react";
import { Checkbox, IconButton, TextField } from "@material-ui/core";
import "../styles/EmailList.css";
import axios from "axios";
import moment from "moment";
import { UserContext } from "./Dashboard";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"; // Import error icon
import EmailRow from "./EmailRow";

function EmailList() {
  const [items, setItems] = useState([]);
  const [input, setInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.selectedCategory === "") {
      console.log("tuka");
      setItems([]);
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/items/${user.selectedCategory}`
      )
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => console.log("error0t" + err));
  }, [refresh, user]);

  const addItem = (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message
    console.log("user.selectedCategory");
    console.log(user.selectedCategory);
    if (!user.selectedCategory) {
      // Show toast notification if no category is selected
      setErrorMessage("Please select or add a category first!"); // Set error message
      return;
    }

    if (input === "") {
      setErrorMessage("Enter Something."); // Set error message for empty input
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/items`, {
        item: input,
        categoryID: user.selectedCategory,
        date: new Date(),
      })
      .then((res) => {
        setInput("");
        setRefresh(!refresh);
      })
      .catch((err) => setErrorMessage("Error adding item: " + err.message));
  };

  return (
    <div className="emailList">
      <div className="emailList__sections">
        <h1>{user.selectedCategoryName}</h1>
      </div>
      <div className="emailList__list">
        {items.map((item, id) => (
          <EmailRow
            key={id}
            id={item._id}
            title={item.item}
            time={moment(item.date).fromNow()}
            refresh={() => setRefresh(!refresh)}
          />
        ))}
        <form
          onSubmit={addItem}
          style={{
            paddingTop: "20px",
          }}
        >
          <TextField
            id="standard-basic"
            label="Add Item"
            fullWidth
            onInput={(e) => setInput(e.target.value)}
            autoComplete="off"
            value={input}
          />
        </form>
      </div>
      {errorMessage && ( // Render toast if there's an error
        <div
          style={{
            position: "fixed", // Change to fixed positioning
            bottom: "20px", // Position at the bottom
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#d9534f",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            zIndex: 1000,
            display: "flex",
            alignItems: "center", // Align items in the center vertically
          }}
        >
          <ErrorOutlineIcon style={{ marginRight: "10px" }} />{" "}
          {/* Error icon */}
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

export default EmailList;
