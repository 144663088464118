import React, { useState } from 'react'
import Login from './components/Login'
import Register from './components/Register'
import Dashboard from './components/Dashboard'
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Mail from './components/Mail';
import EmailList from './components/EmailList';
import SendMail from './components/SendMail';


function App() {

    const [user, setUser] = useState(localStorage.getItem('ID'));

    return (
        <Router>
            {!user ? (
                <Switch>
                    <Route path='/login' component={Login} />
                    <Route path='/register' component={Register} />
                    <Redirect to='/login' />
                </Switch>
            ) : (
                    <Switch>
                        <Route path='/dashboard' component={Dashboard} />
                        <Redirect to='/dashboard' />
                    </Switch>
                )}
        </Router>
    )
}

export default App
