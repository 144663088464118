import React, { useState } from "react";
import "../styles/Header.css";
import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function Header() {
  const signOut = () => {
    localStorage.clear();
    window.location.reload();
  };
  const [user, setUser] = useState(localStorage.getItem("userName"));

  return (
    <div className="header">
      <h1 className="header-h1">HandyApp</h1>

      <div className="header__left">
        <h4 style={{ color: "#fff" }}>We keep it simple</h4>
        <h2 style={{ color: "#fff", marginTop: "-20px", marginLeft: "20px" }}>
          Simple is good
        </h2>
      </div>

      <div className="header__right">
        <h2 style={{ color: "#fff" }}>{user}</h2>
        <IconButton style={{ color: "white" }} onClick={signOut}>
          <ExitToAppIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default Header;
